import useEmblaCarousel from "embla-carousel-react";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from "react";
import { TestimonialCard } from "../components";
import { TESTIMONIALS_DATA } from "../utils/testimonials-data";

export function TestimonialsPage() {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "start",
    slidesToScroll: 1,
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const autoScrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const startAutoScroll = useCallback(() => {
    if (autoScrollIntervalRef.current) return;
    autoScrollIntervalRef.current = setInterval(() => {
      if (emblaApi) emblaApi.scrollNext();
    }, 7000);
  }, [emblaApi]);

  const stopAutoScroll = useCallback(() => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);

    return () => {
      emblaApi.off("select", onSelect);
      emblaApi.off("reInit", onSelect);
    };
  }, [emblaApi, onSelect]);

  useEffect(() => {
    if (isAutoScrolling) {
      startAutoScroll();
    } else {
      stopAutoScroll();
    }

    return () => stopAutoScroll();
  }, [isAutoScrolling, startAutoScroll, stopAutoScroll]);

  const handleMouseEnter = () => setIsAutoScrolling(false);
  const handleMouseLeave = () => setIsAutoScrolling(true);

  return (
    <div id="testimonials" className="min-h-screen relative bg-gradient-to-br from-[#0ccdef] via-blue-400 to-[#0a9bbf] overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Heading and Subheading */}
        <div className="text-center mb-20 relative z-10">
          <div className="relative inline-block">
            
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-5xl font-bold text-white mb-6 relative z-10"
            >
              What people say
            </motion.h2>
            <div 
              className="absolute inset-0 opacity-[0.15] z-0"
              style={{
                backgroundImage: `linear-gradient(#fff 2px, transparent 2px),
                  linear-gradient(90deg, #fff 2px, transparent 2px)`,
                backgroundSize: '40px 40px',
                backgroundPosition: 'center center',
                top: '-50%',
                left: '-50%',
                right: '-50%',
                bottom: '-50%',
              }}
            />
          </div>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-lg text-black-100 max-w-2xl mx-auto relative z-10"
            >
              Discover what happy and satisfied users have to say about their experiences with Rent Hub.
            </motion.p>
          </div>
          <div className="relative">

        <div className="overflow-hidden sm:mt-4" ref={emblaRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="flex space-x-4">
            {TESTIMONIALS_DATA.map((testimonial) => (
              <div
                key={testimonial.id}
                className="flex-[0_0_100%] md:flex-[0_0_50%] lg:flex-[0_0_33.33%] min-w-0 px-2"
              >
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </div>
        </div>
        <button
          className="absolute sm:hidden lg:block -left-4 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
          onClick={scrollPrev}
          aria-label="Previous testimonial"
        >
          <ChevronLeft className="w-6 h-6 text-gray-600" />
        </button>
        <button
          className="absolute sm:hidden lg:block -right-4 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
          onClick={scrollNext}
          aria-label="Next testimonial"
        >
          <ChevronRight className="w-6 h-6 text-gray-600" />
        </button>
        <div className="flex justify-center mt-4">
          {scrollSnaps.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full mx-1 ${index === selectedIndex ? "bg-primary" : "bg-gray-300"}`}
              onClick={() => scrollTo(index)}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
          </div>
      </div>
    </div>
  )
}

