import { CardData } from "../types";


export const DUMMY_ABOUT_CARD_DATA: CardData[] = [
  {
    title: "Our story",
    description:
      "Founded in 2024, RentHub was created to address the common challenges faced by landlords, property managers and tenants. Our goal is to offer a seamless experience through innovative technology.",
  },
  {
    title: "Our mission",
    description: "To simplify property management by providing an all-in-one platform that meets the needs of both landlords and tenants.",
  },
  {
    title: "Our vision",
    description: "To revolutionize the property management industry by leveraging cutting-edge technology to create effortless and efficient experiences for everyone involved."
  }
,  
  {
    title: "Customer satisfaction",
    description:
      "Our dedicated support team is always ready to assist you. Whether you have questions, need help with the app, or have any concerns, we are here to provide timely and effective solutions.",
  },
  {
    title: "Quality assurance",
    description:
      "At RentHub, we are dedicated to providing the highest quality of service to our users. Our commitment to excellence is reflected in every aspect of our platform.",
  },
  {
    title: "Our leadership",
    description: "RentHub is led by a team of seasoned professionals with decades of experience in real estate, technology, and customer service."
  }
  
];
