import { AboutUsCardProps } from "../types";
export function AboutUsCard({ title, description, subDescription }: AboutUsCardProps) {
  return (
    <div className="transform hover:scale-105 transition-transform duration-300 z-10 
      m-2 sm:mx-6 md:m-4 p-4 sm:p-6 md:p-4 
      hover:shadow-lg 
      flex flex-col justify-center items-start 
      rounded-lg shadow-md  cursor-pointer bg-white bg-opacity-40 backdrop-blur-xl border border-white/10 hover:border-white/20 ">
      <div className="flex items-center gap-4 mb-4">
        <h2 className="text-lg sm:text-xl  font-bold text-blue-700">
          {title}
        </h2>
      </div>
      <p className="text-sm sm:text-base  font-semibold text-black mb-4">
        {description}
      </p>
      {/* {subDescription && (
        <ul className="list-disc pl-5 text-sm sm:text-base font-semibold text-gray-800 space-y-1">
          {subDescription.map((desc, index) => (
            <li key={index}>{desc}</li>
          ))}
        </ul>
      )} */}
    </div>
  );
}
