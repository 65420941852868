import { AnimatePresence, motion } from "framer-motion";
import { FAQ_DATA } from "utils";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion";

export function FAQSection() {
  return (
    <div id="faqs" className="bg-gradient-to-b from-blue-100 via-[#0ccdef] to-[#0a9bbf]">
      <div className="w-full max-w-4xl mx-auto px-4 py-16 text-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-[#1a237e]">
            Frequently Asked Questions
          </h1>
          <p className="mt-4 text-lg text-gray-700">
            Welcome to RentHub's Frequently Asked Questions. Here you'll find answers to common questions 
            about our platform, its features, and how to get the most out of it.
          </p>
        </motion.div>

        <Accordion type="single" collapsible className="space-y-4">
          <AnimatePresence>
            {FAQ_DATA.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <AccordionItem
                  value={`item-${index}`}
                  className="bg-white bg-opacity-40 hover:cursor-pointer   border-white/10 hover:border-white/20  rounded-lg shadow-md border px-6"
                >
                  <AccordionTrigger className="text-left hover:no-underline py-6">
                    <span className="text-lg font-medium text-[#1a237e]">
                      {faq.question}
                    </span>
                  </AccordionTrigger>
                  <AccordionContent className="text-black">
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="pb-4 text-lg text-start"
                    >
                      {faq.answer}
                    </motion.div>
                  </AccordionContent>
                </AccordionItem>
              </motion.div>
            ))}
          </AnimatePresence>
        </Accordion>
      </div>
    </div>
  );
}
