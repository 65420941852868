import { CustomMap } from "../components";
import { ADDRESS } from '../constants';
import {MdAttachEmail, MdLocalPhone} from 'react-icons/md'



export function ContactUs() {
  return (
    <div id="contact" className=" min-h-screen bg-gradient-to-bl to-[#0ccdef] via-blue-300 from-[#0a9bbf]   ">
      <div className="flex flex-col items-center justify-center py-8 px-4">
        <div className="flex items-center justify-center w-full">
          <div className="bg-custom-gray border border-custom-stroke backdrop-blur-custom  p-8 w-full max-w-screen-lg md:min-h-[90vh]  sm:min-h-[100%]  flex flex-col lg:flex-row gap-2 justify-center mt-5">
            <div className="flex flex-col w-full lg:w-1/2 sm:mb-1 md:mb-4 lg:mb-0 ">
              <div className="mb-8">
                <h2 className="text-2xl font-bold mb-2 text-black ">Let’s talk </h2>
                <p className="text-lg text-black ">
                  Have any questions or need assistance? Reach out to our team  and we will get back to you promptly
                </p>
              </div>
              <div className="space-y-4 ">
  <div className="flex items-center space-x-2">
  <MdAttachEmail size={30} className="text-blue-900 hover:text-blue-700" />
    <a href={`mailto:${ADDRESS.email}`} className="text-gray-800 hover:cursor-pointer text-lg">
   {ADDRESS.email}
    </a>
  </div>

  <div className="flex items-center space-x-2">
     <MdLocalPhone size={30} className="text-blue-900 hover:text-blue-700" />
    <a href={`tel:${ADDRESS.phone1}`} className="text-gray-800 hover:cursor-pointer text-lg">
     {ADDRESS.phone1}
    </a>
  </div>
  <div className="flex items-center space-x-2">
     <MdLocalPhone size={30} className="text-blue-900 hover:cursor-pointer" />
    <a href={`tel:${ADDRESS.phone2}`} className="text-gray-800 hover:cursor-pointer hover:text-underline text-lg">
     {ADDRESS.phone2}
    </a>
  </div>
               
              </div>
            </div>
              <div className="w-full  md:ml-4 lg:w-1/2  items-center">
                <CustomMap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
