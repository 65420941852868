
export function Policy() {
  return (
    
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#f5f5f5', marginTop: '40px' }}>
      <iframe
        src="/policy.html"
        style={{ width: '100%',  height: '100%', border: 'none' }}
        title="Privacy Policy"
      ></iframe>
    </div>
  );
};