import { FaqDataInterface } from "../types";


export const FAQ_DATA: FaqDataInterface[] = [
  {
    question: "What is RentHub?",
    answer:
      "A platform that streamlines the rental experience for both landlords and tenants. It provides real-time updates on property status, facilitates communication through an in-built chat feature, and supports both automated and manual receipt payments. Additional features include lease application and tracking, among others.",
  },

  {
    question: "How does the lease application process work?",
    answer:
      "The tenant searches for a property, applies for a lease, and waits for approval from the property management. Additionally, property management can apply for a lease on behalf of a tenant, and in this case, it will be approved automatically. This feature is aimed at assisting potential tenants who do not have smartphones.",
  },

  {
    question: "What payment methods does it support?",
    answer: "We have a ubiquitous paper receipt upload process. Additionally, we have integrated a virtual wallet that uses Equity Bank and M-Pesa. More forms of payment e.g. visa, mastercard, and bank payments are in the offing.",
  },

  {
    question: "Is RentHub only available in Kenya?",
    answer:
      "Rent Hub is available for use worldwide. All the features including the paper receipt upload process are available to anyone around the world. However, the integrated automated payment processes using equity bank and mpesa are currently available exclusively to local citizens in Kenya. More payment methods to accommodate more countries are on the way. Send us a request and we will consider prioritizing automated payments for your country of residence.",
  },
];
