import { motion } from "framer-motion";
import { Star } from 'lucide-react';
import { AboutUsCard } from "../components";

interface AboutUsInterface {
  items: { title: string; description: string; subDescription?: string[] }[];
}

export function AboutUs({ items }: AboutUsInterface) {
  return (
    <div id="about" className="min-h-screen relative bg-[#0a9bbf] overflow-hidden">
    <div className="absolute top-0 left-0 sm:w-[200%] lg:w-[200%] sm:h-[20%] lg:h-[60%] md:h-[40%] bg-[#0ccdef] transform sm:-skew-y-3 lg:-skew-y-4 origin-left sm:-translate-x-1/2 lg:-translate-x-1/4" />
    <div className="relative z-10 lg:max-w-7xl lg:mx-auto lg:px-4 pt-8">
      <div className="absolute top-12 right-20 hidden sm:block">
        <Star className="w-6 h-6 text-[yellow]" />
      </div>
      <div className="absolute top-16 right-12 hidden sm:block">
        <div className="w-2 h-2 rounded-full bg-gray-500" />
      </div>
      <div className="absolute top-20 right-16 hidden sm:block">
        <div className="w-2 h-2 rounded-full bg-gray-500" />
      </div>
      <div className="text-center mt-16 mb-20">
        <h1 className="text-4xl md:text-5xl font-bold text-blue-700 mb-6">About us</h1>
        <p className="max-w-2xl mx-auto text-[#2A2A2A] opacity-80 text-lg md:text-lg">
        Reimagining Property Management: Our Story, Values, Vision, and Commitment to Excellence
        </p>
      </div>
      <motion.div 
        className="flex flex-col sm:flex-row flex-wrap justify-center items-center gap-8 sm:gap-6 mb-20 px-4 sm:px-0"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3  md:grid-cols-2 2xl:grid-cols-4 gap-4">
          {items.map((item, index) => (
            <AboutUsCard key={index} title={item.title} description={item.description} subDescription={item.subDescription} />
          ))}
          </div>
       
      </motion.div>
    </div>
  </div>)
}
