import { AboutUs, ContactUs, FAQSection, FeaturesPage, Landing, TestimonialsPage } from "../sections";
import { DUMMY_ABOUT_CARD_DATA } from "../utils";

export function MainPage() {
  //Put all the sections needed for the main page
  return (
    <>
      <Landing />
      <AboutUs items={DUMMY_ABOUT_CARD_DATA} />
      <FeaturesPage  />
      <FAQSection />
      <TestimonialsPage  />
      <ContactUs />
    </>
  );
}
