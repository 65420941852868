import { featureCardDataInterface } from "../types";
import Communication from "../images/communication.png";
import Screening from "../images/Group 19.png";
import Lease from "../images/lease.png";
import Search from "../images/Online-property-search.png";
import Rating from "../images/positive-rating-and-customer-feedback.png";
import Finance from "../images/Secure-online-payment-on-smartphone.png";
import Updates from "../images/time-management-blocks-with icons.png";
import Tracking from "../images/User-authentication-in-mobile-application.png";



export const FEATURE_CARD_DATA: featureCardDataInterface[] = [
  {
    backgroundColor: "#EFC4F0",
    hoverBackgroundColor: "#D8A2D9",
    icon: Tracking,
    title: "Application Tracking",
    description: "Automatically tracking and responding intelligently to tenant leads after pre-screening tenants.",
    position:"left"
  },
  {
    backgroundColor: "#F4848A",
    hoverBackgroundColor: "#D96368",
    icon: Updates,
    title: "Real-Time Updates",
    position:"left",
    description: "Tenants stay informed throughout the leasing journey, such as their application status.",
  },
  {
    backgroundColor: "#F2E89D",
    hoverBackgroundColor: "#E2D87D",
    icon: Communication,
    title: "Communication",
    position:"left",
    description:
      "Our app facilitates direct communication between tenants and landlords or property managers throughout the leasing process.",
  },
  {
    backgroundColor: "#E7ADC7",
    hoverBackgroundColor: "#D38BA7",
    icon: Finance,
    title: "Online Rent & Financial",
    position:"left",
    description: "Fast and effortless payments of rents and automated late fees due to instant bank verification.",
  },
  {
    backgroundColor: "#EDE1E1",
    hoverBackgroundColor: "#D4C5C5",
    icon: Search,
    title: "Property Search",
    position:"right",
    description:
      "Our app offers a comprehensive and intuitive search feature, allowing tenants to easily browse and find their ideal rental homes.",
  },
  {
    backgroundColor: "#E2E1F3",
    hoverBackgroundColor: "#C4C3D7",
    icon: Screening,
    title: "Tenant Screening",
    position:"right",
    description: "Tenants are vetted through our comprehensive credit report and eviction records to find the most qualified tenants.",
  },
  {
    backgroundColor: "#DFFBF4",
    hoverBackgroundColor: "#BDE5D9",
    icon: Lease,
    title: "Lease Management",
    position:"right",
    description: "Our app provides a seamless leasing experience allowing tenants to review and sign leases electronically.",
  },
  {
    backgroundColor: "#F6EDDD",
    hoverBackgroundColor: "#E1D0B8",
    icon: Rating,
    position:"right",
    title: "Ratings & Viewings",
    description: "Tenants can access ratings and reviews for properties within the app helping them make informed decisions.",
  },
];
