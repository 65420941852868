import { FeaturesCard } from "components";
import { motion } from "framer-motion";
import { FEATURE_CARD_DATA } from "../utils";

export function FeaturesPage() {

  return (
    <div id="features"className="min-h-screen bg-gradient-to-br from-blue-100 via-[#0ccdef] to-[#0a9bbf] py-10 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className=" font-bold  text-6xl  mb-4 text-[#1a237e]"
          >
            Features
          </motion.h1>
        </div>

        <div className="relative">
            <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
              {FEATURE_CARD_DATA.map((feature, index) => (
             <motion.div
             key={feature.title}
             animate={{ opacity: 1, x: 0 }}
             transition={{
               opacity: { duration: 0.6, delay: index * 0.2 },
               x: { duration: 0.6, delay: index * 0.2 },
             }}
           >
           <FeaturesCard
            key={index}
            backgroundColor={feature.backgroundColor}
            hoverBackgroundColor={feature.hoverBackgroundColor}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
            className={feature.className}
          />
           </motion.div>
           
              ))}
            </div>

        </div>
      </div>
    </div>
  );
}
