import { Button } from "../components/ui/button";
import { motion, useScroll } from "framer-motion";
import { Download } from "lucide-react";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { NavHashLink } from "react-router-hash-link";
import { Drawer } from "../components";
import RentHubLogo from "../images/logo.png";
import "../index.css";
import { CALENDLY_URL, PLAYSTORE_URL } from "../constants";
import { PopupButton } from "react-calendly";

const HeaderTitles = ["Home", "About", "Features", "Testimonials", "FAQs", "Contact"]

export function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { scrollY } = useScroll();

  useEffect(() => {
    const unsubscribe = scrollY.on("change", (latest: any) => {
      setIsScrolled(latest > 50);
    });
    return () => unsubscribe();
  }, [scrollY]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <header className="sticky top-0 z-50 w-full bg-primary border-b">
      <div className="w-full flex items-center justify-between lg:flex-row">
        <motion.header
          className={`fixed top-0 left-0 right-0 z-50 transition-colors duration-300 ${
            isScrolled ? "bg-background/80 backdrop-blur-md border-b" : "bg-transparent"
          }`}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 100, damping: 20 }}
        >
          <div className=" mx-auto px-4">
            <div className="flex h-16 items-center justify-between">
              <a href="/" className="flex items-center gap-2">
                <img src={RentHubLogo} alt="RentHub Logo" className="h-14 w-auto md:h-16" />
                <span className="sr-only">RentHub Logo</span>
              </a>

              <motion.ul
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="hidden lg:flex items-center space-x-8 text-sm font-medium"
              >
                {HeaderTitles.map((item) => (
                  <NavHashLink
                    key={item}
                    to={`/#${item.toLowerCase()}`}
                    className="text-sm font-medium transition-colors hover:text-primary"
                  >
                    {item}
                  </NavHashLink>
                ))}
              </motion.ul>

              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className=" items-center space-x-4"
              >
                
          <div className="hidden lg:inline-flex lg:flex items-center gap-2">
          <PopupButton
                  url={CALENDLY_URL}
                  rootElement={document.getElementById("root")!}
                  text="Request demo"
                  className="rounded-full p-2 text-black border-primary bg-gradient-to-br from-blue-400 via-blue-500 to-blue-600 bg-blue-300 hover:text-black transition-colors hover:opacity-70"
                />
                <Button className="   rounded-full bg-secondary text-black hover:bg-secondary/90">
                  <a href={PLAYSTORE_URL} target="_blank" rel="noopener noreferrer" className="flex flex-row">
                    <Download className="mr-2 h-4 w-4" /> Download
                  </a>
                </Button>
        </div>
        <button onClick={toggleDrawer}  className="lg:hidden pr-8 text-gray-700 hover:text-gray-900">
          <FiMenu className="text-3xl" />
          <span className="sr-only">Toggle Menu</span>
        </button>
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />

        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
              </motion.div>
            </div>
          </div>
        </motion.header>

        
      </div>
    </header>
  );
}
