import { TestimonialDataInterface } from "types";
import "../index.css";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";


export function TestimonialCard({testimonial}:{testimonial:TestimonialDataInterface} ) {
  return (
    
    <div className="h-full p-8 rounded-2xl bg-gradient-to-b hover:cursor-pointer from-white/10 to-white/5 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-colors">
    <div className="flex items-center gap-4 mb-6">
      <Avatar className="h-12 w-12 border-2 border-white/20">
        <AvatarImage key={testimonial.image} src={testimonial.image} alt={testimonial.name} />
         <AvatarFallback>{testimonial.name.charAt(0)}</AvatarFallback>
      </Avatar>
      <div>
        <h3 className="text-lg font-semibold text-white">
          {testimonial.name}
        </h3>
        <p className="text-sm text-black-300">
          {testimonial.role}
        </p>
      </div>
    </div>
    <p className="text-black-200 leading-relaxed">
      {testimonial.content}
    </p>
  </div>
);
}
